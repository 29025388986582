import { useContext } from "react";
import { StepperContext } from "../../../context/StepperContext";

const PersonalInformations = () => {
  const {userData, setUserData} = useContext(StepperContext);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  }; 

  // console.log(userData)
  return (
    <div className="mt-8 grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="prenom"
          className="block text-sm font-medium text-gray-700"
        >
          Prénom :
        </label>

        <input
          onChange={handleChange}
          value={userData["prenom"] || ""}
          type="text"
          id="prenom"
          name="prenom"
          placeholder="Prénom"
          className="mt-1 w-full rounded-md border-gray-200 border-1  bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="nom"
          className="block text-sm font-medium text-gray-700"
        >
          Nom :
        </label>

        <input
          onChange={handleChange}
          value={userData["nom"] || ""}
          type="text"
          id="nom"
          name="nom"
          placeholder="Nom :"
          className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label
          htmlFor="adresse"
          className="block text-sm font-medium text-gray-700"
        >
          Adresse :
        </label>

        <input
          onChange={handleChange}
          value={userData["adresse"] || ""}
          type="adress"
          id="adresse"
          name="adresse"
          placeholder="Adresse"
          className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label
          htmlFor="codePostal"
          className="block text-sm font-medium text-gray-700"
        >
          Code Postal :
        </label>

        <input
          onChange={handleChange}
          value={userData["codePostal"] || ""}
          type="number"
          id="codePostal"
          name="codePostal"
          placeholder="Code postal"
          className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <label
          htmlFor="ville"
          className="block text-sm font-medium text-gray-700"
        >
          Ville :
        </label>

        <input
          onChange={handleChange}
          value={userData["ville"] || ""}
          type="text"
          id="ville"
          name="ville"
          placeholder="Ville"
          className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>
    </div>
  );
};

export default PersonalInformations;
