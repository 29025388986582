import React from "react";
import "./ArtisanMainSide.css"
import {BsSearch} from 'react-icons/bs'
import {VscSettings} from 'react-icons/vsc'
import ProjetCard from "../projetCard/ProjetCard";

const ArtisanMainSide = ({authUser}) => {
  
  return (
   <div className="M-artisan-wrapper">
    <div className="artisan-home paddings innerWidth M-artisan-container">
      <div className="artisan-search-bar gap-10">
        <div className="flexCenter search-bar">
          <BsSearch color="var(--blue)" size={25}/>
          <input type="text" placeholder='Rechercher par mot-clé, ville, matériau...'/>
          <button className="button">Rercherche</button>
        </div>
        <button className="button2 d-flex items center mt-3"><VscSettings/> Filtrer</button>
      </div>

      <div className="py-3">
        <ProjetCard authUser = {authUser}/>
      </div>


    </div>
   </div>
  );
};

export default ArtisanMainSide;
