import React, { useContext } from "react";
import Select from 'react-select';
import { StepperContext } from "../../../context/StepperContext";



const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const ProfessionalInformation = ({ categories }) => {
  const {userData, setUserData} = useContext(StepperContext);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  }; 

  // console.log(userData)

  return (
    <div className="mt-8 grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="denomination"
          className="block text-sm font-medium text-gray-700"
        >
          Dénomination :
        </label>

        <input
          onChange={handleChange}
          value={userData["denomination"] || ""}
          type="text"
          id="denomination"
          name="denomination"
          placeholder="si société"
          className="mt-1 w-full rounded-md border-gray-200 border-1  bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="siret"
          className="block text-sm font-medium text-gray-700"
        >
          N° Siret :
        </label>

        <input
        onChange={handleChange}
        value={userData["siret"] || ""}
          type="number"
          id="siret"
          name="siret"
          placeholder=" N° Siret :"
          className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="D1" className="block text-sm font-medium text-gray-700">
          D1 :
        </label>

        <input
        onChange={handleChange}
          value={userData["D1"] || ""}
          type="text"
          id="D1"
          name="D1"
          placeholder="Inscription à la chambre des métiers"
          className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="D1" className="block text-sm font-medium text-gray-700">
          Téléphone :
        </label>

        <input
        onChange={handleChange}
          value={userData["telephone"] || ""}
          type="text"
          id="telephone"
          name="telephone"
          placeholder="Entrez votre numéro de Téléphone"
          className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="codePostal"
          className="block text-sm font-medium text-gray-700"
        >
          Categories :
        </label>

        <Select
        isMulti
        className = 'mt-1.5'
        options={options}
        name = 'categories'
      />
      </div>   
    </div>
  );
};

export default ProfessionalInformation;
