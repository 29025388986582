import React, { useState } from "react";
import Stepper from "../Stepper/Stepper";
import StepperControl from "../Stepper/StepperControl";
import PersonalInformations from "../Stepper/Steps/PersonalInformations";
import ProfessionalInformation from "../Stepper/Steps/ProfessionalInformation";
import Account from "../Stepper/Steps/Account";
import { StepperContext } from "../../context/StepperContext";
import axios from 'axios';
import { baseUrl } from "../../utils/constant_api";

const ArtisanRegisterForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState([]);
  const [finalData, setFinalData] = useState([]);

  const steps = [
    "Informations Personnelles",
    "Informations Professionnelles",
    "Identifiants",
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <PersonalInformations />;
      case 2:
        return <ProfessionalInformation />;
      case 3:
        return <Account />;
      default:
    }
  };

  function submitData () {
    setFinalData(userData);
    axios.post(`${baseUrl}/artisan/create`, finalData)
    .then(response => {
      console.log(response)
      console.log('Form submitted successfully!', response.data);
      setFinalData({});
      localStorage.setItem('email', email)
      window.location.href = "/confirm";
    })
    .catch(error => {
      console.error('Error submitting form:', error);
    });
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  return (
    <div className="container flex justify-center">
      <div className="shadow-xl rounded-2xl pb-2 bg-white w-full max-w-3xl ">
        {/* Stepper */}
        <div className=" horizontal mt-5">
          <Stepper steps={steps} currentStep={currentStep} />
          {/* display stepper component */}
          <div className="my-10 p-10">
            <StepperContext.Provider
              value={{
                userData,
                setUserData,
                finalData,
                setFinalData,
              }}
            >
              {displayStep(currentStep)}
            </StepperContext.Provider>
          </div>
        </div>

        {/* StepperControls */}
        <StepperControl
          handleClick={handleClick}
          currentStep={currentStep}
          submitData={submitData}
          steps={steps}
        />
      </div>
    </div>
  );
};

export default ArtisanRegisterForm;
