import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { FaCircleArrowRight } from "react-icons/fa6";

const Hero = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [secteur, setSecteur] = useState("");
  const [input, setInput] = useState("");
  const [results, setResults] = useState([]);
  const [secteurResults, setSecteurResults] = useState([]);

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  const fetchData = (value) => {
    fetch(`${baseUrl}/categories`) 
      .then((response) => response.json())
      .then((json) => {
        const searchResults = json.filter((categorie) => {
          return (
            value &&
            categorie &&
            categorie.name &&
            categorie.name.toLowerCase().includes(value)
          );
        });
        setCategories(searchResults);
      });
  };

  const goToCreateProject = (categorie, secteur) => {
    localStorage.setItem('cat_project', categorie._id)
      if (secteur) {
        navigate(`./pubprojet/${categorie.name}/${secteur.name}` )
        localStorage.setItem('sec_project', secteur._id)
    }else {
      navigate(`./pubprojet/${categorie.name}/xxl-connexion`)
    }
  };


  const onSecteurClick = (secteur) => {
    console.log("***************************");
    console.log(secteur);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .get(`${baseUrl}/categories?secteur = ${secteur}`)
      .then((response) => {
        const category = response.data.categories[0];

        alert(category);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section className="hero-wrapper">
      <div className="paddings innerWidth hero-container">
        {/*left side*/}
        <div className="flexColStart hero-left">
          <div className="flex gap-2 items-center">
          <FaCircleArrowRight color= "red" size="25px" className = "bg-white rounded-full"/>
            <h2 className="hover:underline cursor-pointer" onClick={() => navigate('register')}>Professionel ? c'est ici !</h2>
          </div>
          <div className="hero-title">
            <h1>Quel est votre projet...</h1>
          </div>

          <div className="flexColStart hero-des">
            <h2>Publiez votre projet !</h2>
          </div>

          <div className="flexCenter search-bar">
            <BsSearch color="var(--blue)" size={25} />
            <input
              type="text"
              placeholder="(exemple) : élctricité"
              value={input}
              onChange={(e) => handleChange(e.target.value)}
            />
            <button className="button">Go !</button>
          </div>
          {categories.length > 0 && (
            <div className="results-list">
              {categories?.map((categorie, id) => {
                return (
                  <div key={id} className="result-items">
                    <p onClick={() => goToCreateProject(categorie)}>
                      {categorie.name}
                    </p>
                    {categorie.secteurs?.map((secteur, index) => {
                      return (
                        <p
                          key={index}
                          className="mx-3 text-sm py-1"
                          onClick={() => goToCreateProject(categorie, secteur)}
                        >
                          {secteur.name}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/*rigth side*/}
      </div>
    </section>
  );
};

export default Hero;
