import React from 'react'
import './ProfileDropdown.css'

const ProfileDropdown = ({authUser}) => {
    const logout = ()=> {
        localStorage.clear();
        window.location.href="/";
    }

  const dashboard = () => {
    if(authUser.role == "client") {
      window.location.href="/client-monespace"
    } else if (authUser.role == "admin"){
      window.location.href="/admin"
    }else {
      window.location.href="/artisan-monespace"
    }
  }


  return (
    <div className='flex flex-col dropdownProfile align-items-center'>
         <ul className='flex flex-col gap-4'>
            <li onClick={dashboard}><i className="fa-solid fa-user px-2"></i><span>Mon espace</span></li>
            <li><i className="fa-solid fa-screwdriver-wrench px-2"></i><span>Paramètres</span></li>
            <li onClick={logout}><i className="fa-sharp fa-solid fa-power-off px-2"></i><span >Déconnexion</span></li>
         </ul>
    </div>
  )
}

export default ProfileDropdown