import React, { useEffect, useState } from "react";
import "./ProjetCard.css";
import { BsSearch } from "react-icons/bs";
import { VscSettings } from "react-icons/vsc";
import { CiLocationOn } from "react-icons/ci";
import { BiTimeFive } from "react-icons/bi";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/constant_api";
import axios from "axios";
import moment from "moment";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaLock } from "react-icons/fa";

const ProjetCard = ({ authUser }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [projects, setProjects] = useState([]);

  const getProject = async () => {
    await axios.get(`${baseUrl}/project`).then((res) => {
      setProjects(res.data);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const calculateDistance = (originCoordinates, destinationCoordinates) => {
    const R = 6371;
    const lat1 = originCoordinates.lat;
    const lon1 = originCoordinates.lng;
    const lat2 = destinationCoordinates.lat;
    const lon2 = destinationCoordinates.lng;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    //return distance;
    return distance;
  };

  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const distanceFromPostalCode = (originPostalCode, destinationPostalCode) => {
    const apiKeyIp = "AIzaSyDuUtsSkHZw_j0qPnhAOzHEqUM-MnqZIdI";
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${originPostalCode}&key=${apiKeyIp}`
      )
      .then((response) => {
        const originCoordinates = response.data.results[0].geometry.location;
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${destinationPostalCode}&key=${apiKeyIp}`
          )
          .then((response) => {
            // console.log('*********************************');
            const destinationCoordinates =
              response.data.results[0].geometry.location;
            const distance =
              calculateDistance(originCoordinates, destinationCoordinates) /
              1000;
            // console.log(distance);
            return distance;
          })
          .catch((error) => {
            return "error";
          });
      })
      .catch((error) => {
        return "error";
      });
  };
  // console.log(distanceFromPostalCode(89123, 813000))
  return (
    <div>
      {projects?.map((project, i) => (
        <div className="project-card" key={i}>
          <div className="project-img">
            <img
              src={project.categorie?.images[0]?.url}
              alt="projet-img"
              className="img-fluid"
            />
          </div>

          <div className="project-details">
            <div className="flex items-center justify-between">
              <div>
                <Link
                  to={`./project/${project.categorie?.name}/${project.title}/${project._id}`}
                >
                  <h1>
                    {project.categorie?.name}: {project.title}
                  </h1>
                </Link>
              </div>
              <div>
                {
                  (project.isOpen ? (
                    <div className="flex items-center">
                      <div className="px-2 py-1 rounded-lg bg-green-600 flex items-center text-white">
                        <span>Ouvert</span> 
                        <IoMdCheckmarkCircleOutline size = {18} color="snow"/>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="px-2 py-1 rounded-lg bg-red-600 flex items-center text-white">
                        <span> Fermé</span>
                        <FaLock size = {18} className="ml-2"/>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <p className="mb-4">{project.description}</p>
            <span className="date">
              <BiTimeFive />
              &nbsp; Publié {moment(project.createdAt).fromNow()}
            </span>
            <span className="location">
              <CiLocationOn />
              &nbsp; {project.user.codePostal}, {project.user.ville} (
              {distanceFromPostalCode(
                authUser?.codePostal,
                project.user?.codePostal
              ) + "Km"}
              ?)
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjetCard;
