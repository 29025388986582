import React, { useState } from "react";
import "./MainSide.css";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Clientstats from "./Clientstats";

const MainSide = ({ authUser }) => {


  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [secteur, setSecteur] = useState("");
  const [input, setInput] = useState("");

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  const fetchData = (value) => {
    fetch("http://localhost:5000/api/categories")
      .then((response) => response.json())
      .then((json) => {
        const searchResults = json.filter((categorie) => {
          return (
            value &&
            categorie &&
            categorie.name &&
            categorie.name.toLowerCase().includes(value)
          );
        });
        setCategories(searchResults);
      });
  };

  const goToCreateProject = (categorie, secteur) => {
    localStorage.setItem("cat_project", categorie._id);
    if (secteur) {
      navigate(`./pubprojet/${categorie.name}/${secteur.name}`);
      localStorage.setItem("sec_project", secteur._id);
    } else {
      navigate(`./pubprojet/${categorie.name}/xxl-connexion`);
    }
  };

  return (
    <div className="flexCenter innerWidth pt-14 lg:pt-4">
      <div className="text-xl font-bold mt-7">
        <h2 className="text-red-600">Publiez votre projet !</h2>
      </div>
      <div className="flexCenter search-bar2">
        <BsSearch color="var(--blue)" size={25} />
        <input
          type="text"
          placeholder="(exemple) : élctricité"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
        <button className="button">Go !</button>
      </div>
      {categories.length > 0 && (
        <div className="results-list">
          {categories?.map((categorie, id) => {
            return (
              <div key={id} className="result-items">
                <p onClick={() => goToCreateProject(categorie)}>
                  {categorie.name}
                </p>
                {categorie.secteurs?.map((secteur, index) => {
                  return (
                    <p
                      key={index}
                      className="mx-3 text-sm py-1"
                      onClick={() => goToCreateProject(categorie, secteur)}
                    >
                      {secteur.name}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      <div className="dashboardHero"></div>

      <Clientstats/>

    </div>
  );
};

export default MainSide;
