import React, { useEffect, useState } from 'react'
import "./Header.css"
import {BsSearch} from "react-icons/bs"
import {FiMail} from "react-icons/fi"
import {MdNotifications} from "react-icons/md"
import {AiOutlineUser} from "react-icons/ai"


// import OutsideClickHandler from "react-outside-click-handler"
import { NavLink, Link } from "react-router-dom";
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown'
import '../ProfileDropdown/ProfileDropdown.css'


const Header = ({authUser, categories} = this.props) => {
  
  const goToArtisanRegister = (e)=> {
    window.location.href= "/register"
  }

  const goToConnexion = (e) => {
    window.location.href= "/connexion"
  }

  const [openProfile, setOpenProfile] = useState(false )

  return (<div>
    <div className="bar">
      <div className="container">
        <span> <button className='btn btn-danger btn-sm' onClick={() => goToConnexion()}>&nbsp; Déposer un projet &nbsp;  </button></span>
        <span> <i className="fa fa-calendar-alt"></i> 12 années d'expérience</span>
        <span> <i className="fa fa-star"></i> <i style={{'color':'red'}}>98% clients ravis</i></span>
        <span> <i className="fa fa-thumbs-up"></i> Satisfait ou remboursé</span>
        <span> <i className="fa fa-check-circle text-danger"></i> Artisans vérifiés</span>
        <span> <button className='btn btn-danger btn-sm' onClick={() => goToArtisanRegister()}> <i className="fa fa-wrench"> </i> &nbsp; Je suis un artisan &nbsp; <i className="fa fa-play"></i> </button></span>
      </div>
    </div>

    <section className="top-header-wrapper">
      <div className="flexStart innerWidthHeader h-container py-3">
        
      <Link to="/"><img src="./images/logo.png" className='logo' alt="645994591" width={100}/></Link>
      

      <div className="header-upper-links"> 
        <div className='hidden md:flex'>
          <Link className = "flex items-center gap-01" >
            <FiMail className="fs-2 align-items-center "/>
            <p className='mb-0'>Contact</p>
          </Link>
        </div>
        <div className='hidden md:flex'>
          <Link className = "flex items-center gap-01" >
            <MdNotifications className="fs-2 align-items-center"/>
            <p className='mb-0'>A Propos</p>
          </Link>
        </div>
        
        <div>
          {
            (authUser && authUser !== null) ? (
          <div className = "d-flex align-items-center gap-01" to="/connexion" >
            <AiOutlineUser className="fs-2 align-items-center"/>
            <p className='mb-0 profileName mx-2 capitalize' onClick={()=> setOpenProfile((prev) => !prev)}> { authUser.name } </p>
            {
              openProfile &&  <ProfileDropdown authUser = {authUser}/>
            }
           
          </div>
          ) : (
            <Link className = "d-flex align-items-center gap-01" to="/connexion" >
            <AiOutlineUser className="fs-2 align-items-center"/>
            {/* <p className='mb-0'>Mon compte</p> */}
          </Link>
          )
        }
        </div>
      </div>


      </div>
    </section>

    <section className="bottom-header-wrapper">   
    <div className="b-container flexStart innerWidth">
      <div className="menu-bottom-wrapper">
      <div className="menu-bottom align-items-center d-flex gap-01">

      {categories?.map((categorie) => (
        <div key={categorie._id} className='justify-content-between'>
          <div className="dropdown">
            <button 
              className="btn dropdown-toggle d-flex align-items-center" 
              type="button" id="dropdownMenuButton1" 
              data-bs-toggle="dropdown" aria-expanded="false">
              <span className="me-2 d-inline-block text-white">{categorie.name}</span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {
                  categorie.secteurs?.map((secteur, index) => (<li key={secteur._id}><Link className="dropdown-item text-white" to="#" >{ secteur.name }</Link></li>) )
                }
              </ul> 
          </div> 
        </div>
        ))
        }
      </div>
      </div>
    </div>
    </section>

    </div>
  )
}

export default Header